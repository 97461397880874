import {UserTypeEnum} from "@/enums";

const state = () => ({
    isTokenValid: false,
    accessToken: localStorage.getItem('access_token') || null, // makes sure the user is logged in even after
    userType: null,
    cell: null,
    username: null,
    cellName: null
})

// getters
const getters = {
    isTokenValid(state) {
        return state.isTokenValid;
    },
    token(state) {
        return state.accessToken;
    },
    userType(state) {
        return state.userType;
    },
    username(state) {
        return state.username;
    },
    cell(state) {
        return state.cell;
    },
    cellName(state) {
        return state.cellName;
    }
}

// actions
const actions = {
    changeCell({commit}, cell) {
        commit('changeCell', cell);
    },
    setUsername({commit}, username) {
        commit('setUsername', username);
    },
    setUserType({commit}, userType) {
        commit('setUserType', userType);
    },
    setAccessToken({commit}, token) {
        commit('setAccessToken', token);
    },
    logout({commit}) {
        commit("logout")
    },
    changeCellName({commit}, cellName) {
        commit('changeCellName', cellName);
    },
}

// mutations
const mutations = {
    changeCell(state, cell) {
        state.cell = cell;
    },
    setUsername(state, username) {
        state.username = username;
    },
    setUserType(state, userType) {
        state.userType = userType;
    },
    setAccessToken(state, token) {
        state.accessToken = token;
    },
    logout(state) {
        state.accessToken = null;
        state.username = null;
        state.userType = null;
        state.cell = null;
    },
    changeCellName(state, cellName) {
        state.cellName = cellName;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}