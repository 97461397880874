<template>
    <v-data-table :headers="headers" :items="items" hide-default-footer class="elevation-1" :items-per-page="-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
        </template>
    </v-data-table>
</template>

<script>
export default {
name: "InfoTable",
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>