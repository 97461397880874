<template>
    <v-container>
      <v-expansion-panels v-model="expandedItem" accordion>
        <v-expansion-panel
            v-for="month in months"
            :key="month.id"
        >
          <v-expansion-panel-header>{{ month.name }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <chart-table-week v-if="selectedMonth" :data="data" :headers="headers" :month="selectedMonth.id"
                              @expandedDay="replicateEvent"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </template>
  
  <script>
  import ChartTableWeek from "./ChartTableWeek.vue";
  
  export default {
    components: {ChartTableWeek},
    props: {
      data: {
        type: Array,
        required: true
      },
      headers: {
        type: Array,
        default: [
          {
            text: "Problema",
            align: "start",
            sortable: false,
            value: "issue__name",
          },
          {
            text: "Tiempo Perdido",
            value: "time",
          },
          {
            text: "Numero de Incidencias",
            value: "incidents",
          },
        ]
      }
    },
    data() {
      return {
        expandedItem: null,
        months: [],
        selectedMonth: null
      }
    },
    watch: {
      expandedItem(val) {
        this.selectedMonth = this.months[val];
      }
    },
    methods: {
      getMonths() {
        const currentDate = new Date();
        let month = currentDate.getMonth();
        let months = [];
  
        for (let i = 0; i < 12; i++) {
          if (month % 12 === 0) {
            month = 0;
          }
  
          months.push({
            name: this.monthFromNumberToText(month),
            id: month,
          })
  
          month++;
        }
  
        this.months = months;
      },
      monthFromNumberToText(value) {
        switch (value) {
          case 0:
            return "Enero";
          case 1:
            return "Febrero";
          case 2:
            return "Marzo";
          case 3:
            return "Abril";
          case 4:
            return "Mayo";
          case 5:
            return "Junio";
          case 6:
            return "Julio";
          case 7:
            return "Agosto";
          case 8:
            return "Septiembre";
          case 9:
            return "Octubre";
          case 10:
            return "Noviembre";
          case 11:
            return "Diciembre";
        }
      },
      replicateEvent(item) {
        this.$emit("expandedDay", item);
      }
    },
    mounted() {
      this.getMonths();
    },
  };
  </script>
  
  <style>
  </style>