<template>
    <v-container class="mt-2">
        <v-row>
            <v-col>
                <h2 class="font-weight-medium grey--text text--darken-2">
                    Setup's
                </h2>
                <v-divider class="mt-4 mb-4 pl-2"></v-divider>
                <v-card>
                    <v-data-table
                        :headers="headers"
                        :items="computedData"
                        hide-default-footer
                        class="elevation-0"
                        item-key="id"
                    >
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr
                                    v-for="item in items"
                                    :key="item.id"
                                >
                                    <td>{{ item.cell }}</td>
                                    <td>{{ item.date }}</td>
                                    <td>{{ item.hour }}</td>
                                    <td>{{ item.end }}</td>
                                    <td
                                        v-if="item.inTime"
                                        class="green--text"
                                    >
                                        En tiempo
                                    </td>
                                    <td v-else class="red--text">
                                        Fuera de tiempo
                                    </td>
                                    <td>{{ item.estimated }}</td>
                                    <td>{{ item.real }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            headers: [
                {
                    text: "Celda",
                    value: "cell",
                },
                {
                    text: "Fecha",
                    value: "date",
                },
                {
                    text: "Hora inicio",
                    value: "hour",
                },
                {
                    text: "Hora fin",
                    value: "end",
                },
                {
                    text: "En tiempo",
                    value: "inTime",
                },
                {
                    text: "Estimado (hr)",
                    value: "estimated",
                },
                {
                    text: "Real",
                    value: "real",
                },
            ],
          data: [],
        };
    },
    computed: {
      computedData() {
        return this.data.map(item => {
          let startDate =  new Date(item.start_date);
          let endDate =  new Date();

          if(item.end_date) {
            endDate = new Date(item.end_date)
          }

          let expectedEndTime = new Date(item.start_date);
          expectedEndTime.setHours(startDate.getHours() + item.job__setup_hours)

          const data = {
            cell: item.job__cell__name,
            date: `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`,
            hour: `${startDate.getHours()}:${startDate.getMinutes() < 10 ? "0":""}${startDate.getMinutes()}:${startDate.getSeconds() < 10 ? "0":""}${startDate.getSeconds()}`,
            end: `${endDate.getHours()}:${endDate.getMinutes() < 10 ? "0":""}${endDate.getMinutes()}:${endDate.getSeconds() < 10 ? "0":""}${endDate.getSeconds()}`,
            inTime: endDate <= expectedEndTime,
            estimated: item.job__setup_hours,
            real: this.diff_hours(endDate, startDate)
          }

          return data
        })
      }
    },
    methods: {
        getData() {
          axios.get(process.env.VUE_APP_APIURL + "setupAdmin/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.data = response.data;
          });
        },
        diff_hours(date1, date2) {
          return (Math.abs(date1 - date2) / 36e5).toFixed(2);
        }
    },
    mounted() {
      this.getData()
    }
};
</script>
