<template>
  <v-container class="d-flex justify-end flex-column">
    <v-subheader style="font-size: 24px" class="mb-4 mt-4">Administrar correos para notificaciones</v-subheader>
    <deletable-table :headers="headers"
                     :items="items"
                     btn-text="Agregar Usuario"
                     deletable
                     @delete="confirmDelete"
                     @create="onCreateRegister"
    >
    </deletable-table>

    <dialog-form title="¿Estas Seguro(a)?" ref="deleteModal" @accept="deleteRegister">
      <template v-if="itemToDelete" v-slot:body>
        <v-container>
          <p class="m-4">
            Estas a punto de eliminar el correo "{{ itemToDelete.email }} de la lista.".
          </p>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form title="Creación de nuevo usuario" ref="createRegisterModal" @accept="createRegister" need-close>
      <template v-slot:form>
        <v-container class="m-4">
          <v-text-field
              v-model="itemToCreate.email"
              label="Correo electrónico"
              outlined
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import DeletableTable from "@/components/DeletableTable";
import DialogForm from "@/components/DialogForm";
import axios from "axios";

export default {
  name: "Notifications",
  inject: ["showSnackbar"],
  components: {
    DeletableTable,
    DialogForm,
  },
  data() {
    return {
      headers: [
        {text: 'E-mail', value: 'email', sortable: false},
      ],
      items: [],

      itemToDelete: null,
      itemToCreate: {
        email: null,
      }
    }
  },
  methods: {
    getTableData() {
      // Este metodo traerá la info del API
      axios.get(process.env.VUE_APP_APIURL + "notification/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.items = response.data;
          });
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.$refs.deleteModal.openDialog();
    },
    deleteRegister() {
      // Este metodo va a mandar un DELETE al API
      axios.delete(process.env.VUE_APP_APIURL + "notification/" + this.itemToDelete.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getTableData();
            this.showSnackbar("success", "Registro eliminado correctamente");
            this.itemToDelete = null;
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          })
    },
    onCreateRegister() {
      this.$refs.createRegisterModal.openDialog();
    },
    createRegister() {
      axios.post(process.env.VUE_APP_APIURL + "notification/", this.itemToCreate,
          {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.showSnackbar("success", "Registro creado correctamente");
            this.getTableData();

            this.itemToCreate = {
              email: null,
            };
            this.$refs.createRegisterModal.closeDialog();
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          });
    }
  },
  mounted() {
    this.getTableData();
  }
}
</script>

<style scoped>

</style>