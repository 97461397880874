<template>
  <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Inicio de sesion</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon large>mdi-factory</v-icon>
            </v-toolbar>
            <v-card-text>
              <v-form ref="loginForm" v-model="valid" lazy-validation>
                <v-text-field
                    v-model="username"
                    :rules="[rules.required]"
                    label="Usuario"
                    name="username"
                    prepend-icon="mdi-account"
                    type="text"
                ></v-text-field>
                <v-text-field
                    id="password"
                    v-model="password"
                    :rules="[rules.required]"
                    label="Contraseña"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    @keyup.enter="login(username, password)"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" @click="login(username, password)">Iniciar sesión</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>
import { UserTypeEnum } from '@/enums';
import axios from 'axios';

export default {
  name: "Login",
  inject: ["showSnackbar"],
  data() {
    return {
      username: "",
      password: "",
      valid: true,
      rules: {
        required: value => !!value || 'Campo necesario.',
      }
    }
  },
  methods: {
    login(username, password) {
      if (this.$refs.loginForm.validate()) {
        axios.post(process.env.VUE_APP_API + "api/token/", {username: username, password: password})
            .then(response => {
              this.$store.dispatch('auth/setAccessToken', response.data.token);
              this.$store.dispatch('auth/setUserType', response.data.user.type);
              this.$store.dispatch('auth/setUsername', response.data.user.username);
              this.getData(response.data.token);
            })
            .catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Credenciales incorrectas");
            })
      }
    },
    setInitRoute(userType){
      switch (userType) {
        case UserTypeEnum.ADMIN:
          this.$router.push({name: "Charts"});
          break;
        case UserTypeEnum.SUPERVISOR:
          this.$router.push({name: "Charts"});
          break;
        case UserTypeEnum.PLANNER:
          this.$router.push({name: "Planning"})
          break;
        case UserTypeEnum.PRODUCTION:
          this.$router.push({name: "HxH"})
        default:
          break;
      }
    },
    getData(token) {
      axios.get(process.env.VUE_APP_APIURL + "me/", {headers: {Authorization: `Bearer ${token}`}})
          .then(response => {
            this.$store.dispatch('auth/changeCell', response.data.cell_id);
            this.$store.dispatch('auth/changeCellName', response.data.cell__name);
            this.setInitRoute(response.data.type);

          })
          .catch(error => {
            this.showSnackbar("error", "Ocurrio un error inesperado, si el problema persiste comuniquese con soporte");
          })
    }
  },
}
</script>

<style scoped>
</style>