<template>
    <v-data-table
        :headers="headers"
        :items="dataTable"
        hide-default-footer
        item-key="id"
        multi-sort
        :sort-by="['id']"
        :sort-desc="[false, true]"
        :items-per-page="-1"
        need
    >
        <template v-slot:item.start_date="{ item }">
            {{ formatOneDate(item.start_date) }}
        </template>
        <template v-slot:item.due_date="{ item }">
            {{ formatOneDate(item.due_date) }}
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="$emit('edit', item)">
                mdi-pencil
            </v-icon>
            <v-icon small @click="$emit('delete', item)"> mdi-delete </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: {
        dataTable: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                { text: "Job id", value: "id" },
                { text: "Id Celda", value: "cell__name" },
                { text: "Numero", value: "number" },
                { text: "Fecha de inicio", value: "start_date" },
                { text: "Fecha de finalizacion", value: "due_date" },
                { text: "Numero de parte", value: "part_number" },
                { text: "Piezas totales", value: "total_pieces" },
                { text: "Acciones", value: "actions" },
            ],
        };
    },
    methods: {
        formatOneDate(dateStr) {
            var date = new Date(dateStr);
            var day = date.getDate().toString().padStart(2, "0");
            var month = (date.getMonth() + 1).toString().padStart(2, "0");
            var year = date.getFullYear();

            var hour = date.getHours().toString().padStart(2, "0");
            var minutes = date.getMinutes().toString().padStart(2, "0");
            var seconds = date.getSeconds().toString().padStart(2, "0");

            var formattedDate =
                day +
                "/" +
                month +
                "/" +
                year +
                " " +
                hour +
                ":" +
                minutes +
                ":" +
                seconds;

            return formattedDate;
        },
    },
    mounted() {
        //this.formatDates();
    },
};
</script>
