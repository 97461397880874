const state = () => ({
    employee_number: null,
    serial_number: null,
})

// getters
const getters = {
    employee_number(state) {
        return state.employee_number;
    },
    employee_name(state) {
        return state.employee_name;
    },
}

// actions
const actions = {
    setEmployeeNumber({commit}, employee_number) {
        commit('setEmployeeNumber', employee_number);
    },
    setEmployeeName({commit}, employee_name) {
        commit('setEmployeeName', employee_name);
    },
}

// mutations
const mutations = {
    setEmployeeNumber(state, employee_number) {
        state.employee_number = employee_number;
    },
    setEmployeeName(state, employee_name) {
        state.employee_name = employee_name;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}