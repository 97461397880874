const MonthsEnum = Object.freeze({
    JANUARY: { name: 'Ene', numberYear: 1},
    FEBRUARY: { name: 'Feb', numberYear: 2},
    MARCH: { name: 'Mar', numberYear: 3},
    APRIL: { name: 'Abr', numberYear: 4},
    MAY: { name: 'May', numberYear: 5},
    JUNE: { name: 'Jun', numberYear: 6},
    JULY: { name: 'Jul', numberYear: 7},
    AUGUST: { name: 'Ago', numberYear: 8},
    SEPTEMBER: { name: 'Sep', numberYear: 9},
    OCTOBER: { name: 'Oct', numberYear: 10},
    NOVEMBER: { name: 'Nov', numberYear: 11},
    DECEMBER: { name: 'Dic', numberYear: 12},
  });

export default MonthsEnum;