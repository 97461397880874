<template>
  <v-app>
    <drawer v-if="isLogged"></drawer>

    <v-main>
      <v-snackbar
        :value="snackbar"
        :color="snackbarConfig.type"
        top
        @input="snackbar = false"
        vertical
      >
        {{ snackbarConfig.message }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>

      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import Drawer from "@/components/Drawer";

export default {
  name: 'App',
  components: {
    Drawer
  },
  data() {
    return {
      snackbar: false,
      snackbarConfig: {
        message: null,
        type: null
      }
    }
  },
  watch: {
    snackbar(newVal) {
      if (!newVal) {
        this.snackbarConfig.message = null;
        this.snackbarConfig.type = null;
      }
    }
  },
  provide() {
    return {
      showSnackbar: this.showSnackbar
    };
  },
  methods: {
    showSnackbar(type, message) {
      this.snackbarConfig.message = message;
      this.snackbarConfig.type = type;
      this.snackbar = true;
    }
  }, 
  computed: {
    isLogged(){
      return this.$store.getters["auth/token"] !== null;
    }
  }
};
</script>
