<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{tableTitle}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn v-if="showBtn" outlined color="primary" @click="onButtonClick">
            {{btnText}}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.critical="{ item }">
        <v-chip
          :color="item.critical ? 'red':'green'"
          class="rounded-circle d-inline-block"
          style="height: 1.5rem; width: 1.5rem"
        >
        </v-chip>
      </template>

      <template v-slot:item.real="{ item }">
        <v-chip
          :color="item.goal <= item.real ? 'success':'error'"
        >
          {{item.real}}
        </v-chip>
      </template>

      <template v-slot:item.priority="{ item }">
        <v-chip
          :color="priorityColor(item.priority)"
        >
          {{item.priority}}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
      <v-icon
        size="medium"
        class="me-2"
        @click="$emit('onEdit', item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        size="medium"
        @click="$emit('onDelete', item)"
      >
        mdi-delete
      </v-icon>
    </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {PriorityEnum} from "@/enums";

export default {
  name: "TitleAndActionTable",
  computed: {
    priorityColor() {
      return (priority) => {
        switch (priority){
          case PriorityEnum.LOW:
            return "success";
          case PriorityEnum.MEDIUM:
            return "primary";
          case PriorityEnum.HIGH:
            return "warning";
          case PriorityEnum.CRITICAL:
            return "error";
        }
      }
    }
  },
  data() {
    return {
    }
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    tableTitle: {
      type: String,
      required: true
    },
    btnText: {
      type: String,
      required: false
    },
    showBtn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onButtonClick() {
      this.$emit("buttonClick");
    }
  },
}
</script>


<style scoped>

</style>