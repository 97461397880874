<template>
  <v-navigation-drawer permanent expand-on-hover mini-variant app>
    <v-list>
      <!-- User Info -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ username }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- Menu Subtitle -->
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle> Menú </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>

      <template v-if="showProduction">
        <v-list-item link :to="{ name: 'HxH' }">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text-clock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Hora por Hora</v-list-item-title>
        </v-list-item>
      </template>

      <template v-if="showPlanner">
        <v-list-item link :to="{ name: 'Planning' }">
          <v-list-item-icon>
            <v-icon>mdi-file-upload-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Subir planeacion</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'JobsAdmin' }">
          <v-list-item-icon>
            <v-icon>mdi-file-tree</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Admin. Jobs</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'Onboarding' }">
          <v-list-item-icon>
            <v-icon>mdi-human-capacity-decrease</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Onboarding</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'OnboardingDashboard' }">
          <v-list-item-icon>
            <v-icon>mdi-human-male-board</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Onboarding Dashboard</v-list-item-title>
        </v-list-item>
      </template>

      <template v-if="(showSupervisor || showAdmin)">

        <v-list-item link :to="{ name: 'Charts' }">
          <v-list-item-icon>
            <v-icon>mdi-chart-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Graficas</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="showAdmin" link :to="{ name: 'CellsAdmin' }">
          <v-list-item-icon>
            <v-icon>mdi-hexagon-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Admin. Celdas</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'Pauses' }" >
          <v-list-item-icon>
            <v-icon>mdi-pause</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Pausas</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{name: 'Setups'}">
          <v-list-item-icon>
            <v-icon>mdi-wrench-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Setup's</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'AdminHxH' }">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text-clock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Hora por hora</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'Onboarding' }">
          <v-list-item-icon>
            <v-icon>mdi-human-capacity-decrease</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Onboarding</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'OnboardingDashboard' }">
          <v-list-item-icon>
            <v-icon>mdi-human-male-board</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Onboarding Dashboard</v-list-item-title>
        </v-list-item>
      </template>

      <template v-if="showAdmin">
        <v-list-item link :to="{ name: 'JobsAdmin' }">
          <v-list-item-icon>
            <v-icon>mdi-file-tree</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Admin. Jobs</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'Users' }">
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Usuarios</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'Employees' }">
          <v-list-item-icon>
            <v-icon>mdi-badge-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Empleados</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'Notifications' }">
          <v-list-item-icon>
            <v-icon>mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Notificaciones</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'Notes' }">
          <v-list-item-icon>
            <v-icon>mdi-note-edit</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Notas</v-list-item-title>
        </v-list-item>

      </template>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block @click="logout()">
          <v-icon left>mdi-logout</v-icon>
          Cerrar Sesión
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {UserTypeEnum} from "@/enums";

export default {
  name: "Drawer",
  data() {
    return {
      username: this.$store.getters["auth/username"],
      usertype: this.$store.getters["auth/userType"],
      hour: null,
      showSupervisor:
        this.$store.getters["auth/userType"] ===
        UserTypeEnum.SUPERVISOR,
      showAdmin:
        this.$store.getters["auth/userType"] === UserTypeEnum.ADMIN,
      showPlanner:
        this.$store.getters["auth/userType"] === UserTypeEnum.PLANNER,
      showProduction:
        this.$store.getters["auth/userType"] ===
        UserTypeEnum.PRODUCTION,
    };
  },
  methods: {
    updateHour() {
      const today = new Date();
      this.hour = today.getHours() + ":" + today.getMinutes();
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({name: 'Login'})
    },
  },
  created() {
    setInterval(this.updateHour, 3000);
  },
  
};
</script>

<style scoped>

</style>
