const GraphsNameEnum = Object.freeze({
    PIECES: "Piezas",
    EFFICIENCY: "Eficiencia",
    LOST_TIME: "Tiempo perdido",
    PROBLEMS_PARETO: "Problemas", 
    SCRAP: "Scrap", 
    LINE: "Produccion por linea",
    WASTED: "Pausas"
});

export default GraphsNameEnum;