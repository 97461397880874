import Vue from 'vue'
import VueRouter from 'vue-router'
import AcceptanceCriteria from "@/views/AcceptanceCriteria";
import Users from "@/views/Users";
import Planning from "@/views/Planning";
import Charts from "@/views/Charts"
import HxH from "@/views/HxH";
import Pauses from "@/views/Pauses.vue"
import Setups from '@/views/Setups'
import CellsAdmin from "@/views/CellsAdmin";
import Employees from "@/views/Employees";
import Notifications from "@/views/Notifications";
import Notes from "@/views/Notes";
import JobsAdmin from "@/views/JobsAdmin";
import Login from '@/views/Login';
import axios from "axios";
import {store} from "@/store"
import AdminHxH from "@/views/AdminHxH";
import Onboarding from "@/views/Onboarding.vue";
import OnboardingDashboard from "@/views/OnboardingDashboard.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Login',
    component: Login
  },
  {
    path: '/planning',
    name: 'Planning',
    component: Planning
  },
  {
    path: '/hourxhour',
    name: 'HxH',
    component: HxH
  },
  {
    path: '/pauses',
    name: 'Pauses',
    component: Pauses
  },
  {
    path: '/charts',
    name: 'Charts',
    component: Charts
  },
  {
    path: '/setups',
    name: 'Setups',
    component: Setups
  },
  {
    path: '/acceptance_criteria',
    name: 'AcceptanceCriteria',
    component: AcceptanceCriteria
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/cellsAdmim',
    name: 'CellsAdmin',
    component: CellsAdmin
  },
  {
    path: '/employees',
    name: 'Employees',
    component: Employees
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  {
    path: '/notes',
    name: 'Notes',
    component: Notes
  },
  {
    path: '/jobsAdmin',
    name: 'JobsAdmin',
    component: JobsAdmin
  },
  {
    path: '/adminHxH',
    name: 'AdminHxH',
    component: AdminHxH
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding
  },
    {
    path: '/onboardingDashboard',
    name: 'OnboardingDashboard',
    component: OnboardingDashboard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
    store
})


router.beforeEach((to, from, next) => {
  if (checkIfIsLogin(to, next) === false) {
      if (!router.app.$store.getters["auth/token"]) {
          next({name: "Login"});
      } else {
          if (to.matched.some(record => record.meta.requiresSupervisor)) {
              supervisorProtection(to, from, next);
          } else if (to.matched.some(record => record.meta.requiresAdmin)) {
              adminProtection(to, from, next);
          } else if (to.matched.some(record => record.meta.requiresProduction)) {
            ProductionProtection(to, from, next);
          }else if (to.matched.some(record => record.meta.requiresPlanner)) {
          PlannerProtection(to, from, next);
          }else {
                validateToken(next);
          }
      }
  }
})

function supervisorProtection(to, from, next) {
  if (router.app.$store.getters["auth/userType"] === UserTypeEnum.SUPERVISOR || router.app.$store.getters["auth/userType"] === UserTypeEnum.ADMIN) {
      validateToken(next);
  } else {
      next({name: "Hour"})
  }
}

function adminProtection(to, from, next) {
  if (router.app.$store.getters["auth/userType"] === UserTypeEnum.ADMIN) {
      validateToken(next);
  } else {
      next({name: "Charts"})
  }
}
function PlannerProtection(to, from, next) {
  if (router.app.$store.getters["auth/userType"] === UserTypeEnum.PLANNER) {
      validateToken(next);
  } else {
      next({name: "Planning"})
  }
}
function ProductionProtection(to, from, next) {
  if (this.$store.getters["auth/userType"] === UserTypeEnum.PRODUCTION) {
      validateToken(next);
  } else {
      next({name: "HxH"})
  }
}

function checkIfIsLogin(to, next, ){
  if (to.name === "Login") {
    router.app.$store.dispatch("auth/logout");
    next();
    return true;
}
  return false;
}

const validateToken = (next) => {
  axios.post(process.env.VUE_APP_API + "api/token/verify/", {token: router.app.$store.getters["auth/token"]})
      .then(() => {
          next();
      })
      .catch(() => {
          refreshToken(next);
      })
}

const refreshToken = (next) => {
  axios.post(process.env.VUE_APP_API + "api/token/refresh/", {token: router.app.$store.getters["auth/token"]})
      .then(response => {
          router.app.$store.dispatch("auth/setAccessToken", response.data.token)
          localStorage.setItem('access_token', response.data.token);
          next();
      })
      .catch(() => {
          next({name: "Login"});
      })
}

export default router
