<template>
    <v-container class="mt-3">
        <v-row>
            <v-col>
                <v-row  justify="space-between" align="center">
                    
                        <h2 class="font-weight-medium grey--text text--darken-2">
                            ESTADISTICAS
                        </h2>
                        <v-switch class="d-inline-block" label="Vista detallada" v-model="isDetailView"></v-switch>        
                </v-row>
                <v-divider></v-divider>

                <!-- TABS                 -->
                <v-tabs v-model="tab" grow @change="getChartsData">
                    <v-tab v-for="item in tabsIntems" :key="item.value">
                        {{ item.name }}
                    </v-tab>
                </v-tabs>

                <!-- Items tabs  -->
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        v-for="(item, index) in tabsIntems"
                        :key="index"
                    >
                        <v-container height="20" />
                        <!-- Bar charts  -->
                        <v-row class="pa-1" >
                            <v-spacer></v-spacer>
                            <!-- GRAPH CELL COMPARATION -->
                            <v-col :md="isDetailView ? 12:4">
                                <v-card class="pa-2  fill-height">
                                    <v-divider></v-divider>
                                    <bar-chart-charts
                                        :dataChartParent="cellsEfficiencyData.data"
                                        :labels="cellsEfficiencyData.labels"
                                        :titleBarChart="cellsEfficiencyData.name"
                                        chart-label="Piezas registradas por celda"
                                    ></bar-chart-charts>
                                </v-card>
                            </v-col>

                            <!-- Parts Chart -->
                            <v-col :md="isDetailView ? 6:4">
                                <v-card class="pa-3 fill-height">
                                    <v-divider></v-divider>
                                    <bar-chart-charts
                                        :dataChartParent="piecesData.data"
                                        :labels="piecesData.labels"
                                        :titleBarChart="piecesData.name"
                                        chart-label="Piezas registradas"
                                    ></bar-chart-charts>
                                </v-card>
                            </v-col>
                            <!-- Scrap Chart-->
                            <v-col :md="isDetailView ? 6:4">
                                <v-card class="pa-3 fill-height">
                                    <v-divider></v-divider>
                                    <bar-chart-charts
                                        :dataChartParent="scrapData.data"
                                        :labels="scrapData.labels"
                                        :titleBarChart="scrapData.name"
                                        chart-label="Piezas scrap registradas"
                                    ></bar-chart-charts>
                                </v-card>
                            </v-col>


                            <!-- Lost Time Chart-->
                            <v-col :md="isDetailView ? 12:4">
                                <v-card class="pa-3 fill-height">
                                    <v-divider></v-divider>
                                    <bar-chart-charts
                                        :dataChartParent="wastedData.data"
                                        :labels="wastedData.labels"
                                        :titleBarChart="wastedData.name"
                                        chart-label="Horas en pausas"
                                    ></bar-chart-charts>
                                </v-card>
                            </v-col>


                          <!-- Pareto Chart  -->
                          <v-col :md="isDetailView ? 12:4">
                              <v-card class="pa-2 fill-height">
                                  <v-divider></v-divider>
                                  <v-card-title secondary-title>
                                      Pareto de problemas
                                  </v-card-title>
                                  <pareto-chart
                                      :dataChartParent="paretoItems"
                                  >
                                  </pareto-chart>
                              </v-card>
                          </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>

        <v-row>
            <v-col> </v-col>
        </v-row>
    </v-container>
</template>

<script>
import FrequencyTapsEnum from "@/enums/modules/FrequencyTapsEnum";
import GraphsNameEnum from "@/enums/modules/GraphsNameEnum";
import BarChartCharts from "../components/BarChartCharts.vue";
import ParetoChart from "../components/ParetoChart.vue";
import ChartTableWeek from "../components/Tables/ChartTableWeek.vue";
import ChartTableYear from "../components/Tables/ChartTableYear.vue";
import ChartTable from "../components/Tables/ChartTable.vue";
import axios from "axios";
import {ChartTypeEnum} from "@/enums";

export default {
    components: {
        BarChartCharts,
        ParetoChart,
        ChartTableWeek,
        ChartTableYear,
        ChartTable,
    },
    name: "Charts",
    data() {
        return {
            isDetailView: false,
            tab: 0,
            tabsIntems: [
                {
                    name: "Semanal",
                    value: FrequencyTapsEnum.WEEKLY,
                },
                {
                    name: "Mensual",
                    value: FrequencyTapsEnum.MONTHLY,
                },
                {
                    name: "Anual",
                    value: FrequencyTapsEnum.YEARLY,
                },
            ],
            piecesData: {
              name: GraphsNameEnum.PIECES,
              data: [],
              labels: []
            },
            scrapData: {
              name: GraphsNameEnum.SCRAP,
              data: [],
              labels: []
            },
            wastedData: {
              name: GraphsNameEnum.WASTED,
              data: [],
              labels: []
            },
            cellsEfficiencyData: {
              name: GraphsNameEnum.LINE,
              data: [],
              labels: []
            },
            paretoItems: [],
        };
    },
    methods: {
        getChartsData() {
          this.getParetoData();
          this.getPiecesData();
          this.getScrapData();
          this.getWastedData();
          this.getCellsEfficiencyData();
        },
        getParetoData() {
            axios.get(process.env.VUE_APP_APIURL + `metric/${this.tabsIntems[this.tab].value}/${ChartTypeEnum.PARETO}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.paretoItems = response.data;
            });
        },
        getPiecesData() {
          axios.get(process.env.VUE_APP_APIURL + `metric/${this.tabsIntems[this.tab].value}/${ChartTypeEnum.PIECES}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.piecesData.data = response.data.map(item => item.count);
              this.piecesData.labels = response.data.map(item => item.time);
            });
        },
        getScrapData() {
          axios.get(process.env.VUE_APP_APIURL + `metric/${this.tabsIntems[this.tab].value}/${ChartTypeEnum.SCRAP}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.scrapData.data = response.data.map(item => item.count);
              this.scrapData.labels = response.data.map(item => item.time);
            });
        },
        getWastedData() {
          axios.get(process.env.VUE_APP_APIURL + `metric/${this.tabsIntems[this.tab].value}/${ChartTypeEnum.WASTED}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.wastedData.data = response.data.map(item => item.hours);
              this.wastedData.labels = response.data.map(item => item.time);
            });
        },
        getCellsEfficiencyData() {
          axios.get(process.env.VUE_APP_APIURL + `metric/${this.tabsIntems[this.tab].value}/${ChartTypeEnum.EFFICIENCY}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.cellsEfficiencyData.data = response.data.map(item => item.count);
              this.cellsEfficiencyData.labels = response.data.map(item => item.jobSchedule__job__cell__name);
            });
        }
    },
    mounted() {
        this.getChartsData();
    }
};
</script>

<style></style>
