<template>
  <v-container class="d-flex justify-end flex-column">
    <v-subheader style="font-size: 24px" class="mb-4 mt-4">Onboarding</v-subheader>
    <v-row>
      <v-col>
        <title-and-action-table
            :btn-text="isActiveMeeting ? 'Terminar junta':'Iniciar Junta'"
            table-title="Onboarding de hoy"
            :items="items"
            :headers="headers"
            @buttonClick="startStopMeeting"
            show-btn
        >
        </title-and-action-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <title-and-actions-table
            btn-text="Añadir configuración"
            table-title="Configuración de celdas"
            :items="cellConfigurations"
            :headers="cellConfigurationHeaders"
            @buttonClick="onCreateRegister"
            @onDelete="onDeleteRegister"
            @onEdit="onEditRegister"
            show-btn
        >
        </title-and-actions-table>
      </v-col>
    </v-row>

    <dialog-form title="Creación de configuración" ref="createConfigModal" @accept="createConfiguration" need-close>
      <template v-slot:form>
        <v-container class="m-4">
          <v-text-field
            v-model="itemToCreate.operator"
            label="Operador"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="itemToCreate.cellName"
            type="text"
            label="Celda"
            outlined
          ></v-text-field>
          <v-select
              :items="priorities"
              label="Prioridad"
              v-model="itemToCreate.priority"
          ></v-select>
          <v-text-field
            v-model="itemToCreate.onCharge"
            type="text"
            label="Encargado"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="itemToCreate.goal"
            type="number"
            label="Meta"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="itemToCreate.real"
            type="number"
            label="Realizado"
            outlined
          ></v-text-field>

          <v-switch
              label="Es celda crítica"
              v-model="itemToCreate.critical"
              :model-value="true"
          ></v-switch>
          <v-textarea
              clearable
              label="Acción"
              variant="outlined"
              v-model="itemToCreate.action"
              rows="2"
              auto-grow
              counter="250"
          >
          </v-textarea>
        </v-container>
      </template>
    </dialog-form>



    <dialog-form title="Edición de configuración" ref="editConfigModal" @accept="confirmEdit" need-close>
      <template v-if="itemToEdit" v-slot:form>
        <v-container class="m-4">
          <v-text-field
            v-model="itemToEdit.operator"
            label="Operador"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="itemToEdit.cellName"
            type="text"
            label="Celda"
            outlined
          ></v-text-field>
          <v-select
              :items="priorities"
              label="Prioridad"
              v-model="itemToEdit.priority"
          ></v-select>
          <v-text-field
            v-model="itemToEdit.onCharge"
            type="text"
            label="Encargado"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="itemToEdit.goal"
            type="number"
            label="Meta"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="itemToEdit.real"
            type="number"
            label="Realizado"
            outlined
          ></v-text-field>

          <v-switch
              label="Es celda crítica"
              v-model="itemToEdit.critical"
              :model-value="true"
          ></v-switch>
          <v-textarea
              clearable
              label="Acción"
              variant="outlined"
              v-model="itemToEdit.action"
              rows="2"
              auto-grow
              counter="250"
          >
          </v-textarea>
        </v-container>
      </template>
    </dialog-form>


    <dialog-form title="¿Estas Seguro(a)?" ref="deleteConfigModal" @accept="confirmDelete" need-close>
      <template v-if="itemToDelete" v-slot:body>
        <v-container>
          <p class="m-4">
            Estas a punto de eliminar la configuración de la celda "{{itemToDelete.cellName}}".
          </p>
        </v-container>
      </template>
    </dialog-form>

  </v-container>
</template>

<script>
import DeletableTable from "@/components/DeletableTable";
import DialogForm from "@/components/DialogForm";
import axios from "axios";
import TitleAndActionTable from "@/components/TitleAndActionTable.vue";
import TitleAndActionsTable from "@/components/TitleAndActionsTable.vue";
import {PriorityEnum} from "@/enums";

export default {
  name: "Onboarding",
  inject: ["showSnackbar"],
  components: {
    DeletableTable,
    DialogForm,
    TitleAndActionTable,
    TitleAndActionsTable
  },
  data() {
    return {
      headers: [
          { text: 'Usuario', value: 'username', sortable: false },
          { text: 'Tipo de usuario', value: 'type', sortable: false },
          { text: 'Inicio de la reunión', value: 'start_datetime', sortable: false },
          { text: 'Fin de la reunión', value: 'end_datetime', sortable: false },
      ],
      items: [],
      isActiveMeeting: false,
      cellConfigurations: [],
      cellConfigurationHeaders: [
          //"operator", "priority", "cellName", "action", "onCharge", "critical", "date", "goal", "real"
          { text: 'Celda', value: 'cellName', sortable: true },
          { text: 'Operador', value: 'operator', sortable: false },
          { text: 'Prioridad', value: 'priority', sortable: true },
          { text: 'Encargado', value: 'onCharge', sortable: false },
          { text: 'Meta', value: 'goal', sortable: true },
          { text: 'Realizado', value: 'real', sortable: true },
          { text: 'Es crítica', value: 'critical', sortable: true },
          { text: 'Acción', value: 'action', sortable: false },
          { text: 'Acciones', value: 'actions', sortable: false },
      ],
      itemToCreate: {
        operator: null,
        priority: null,
        cellName: null,
        action: null,
        onCharge: null,
        critical: null,
        goal: null,
        real: null
      },
      priorities: Object.values(PriorityEnum),
      itemToDelete: null,
      itemToEdit: null,
    }
  },
  methods: {
    getTableData() {
      axios.get(process.env.VUE_APP_APIURL + "onboardingMeetings/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.items = response.data;

            this.items.forEach(value => {
              let temporaryStart = new Date(value.start_datetime);
              value.start_datetime = `${temporaryStart.getDate() < 10 ? '0' + temporaryStart.getDate() : temporaryStart.getDate() }/${temporaryStart.getMonth()}/${temporaryStart.getFullYear()} ${temporaryStart.getHours()}:${temporaryStart.getMinutes() < 10 ? '0' + temporaryStart.getMinutes() : temporaryStart.getMinutes()}`
              if (value.end_datetime) {
                let temporaryEnd = new Date(value.end_datetime);
                value.end_datetime = `${temporaryStart.getDate() < 10 ? '0' + temporaryEnd.getDate() : temporaryEnd.getDate() }/${temporaryEnd.getMonth()}/${temporaryEnd.getFullYear()} ${temporaryEnd.getHours()}:${temporaryEnd.getMinutes() < 10 ? '0' + temporaryEnd.getMinutes() : temporaryEnd.getMinutes()}`
              }
            })
          });
    },
    getActiveMeeting() {
      axios.get(process.env.VUE_APP_APIURL + "onboardingMeeting/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.isActiveMeeting = !!response.data;
          });
    },
    startStopMeeting() {
      axios.post(process.env.VUE_APP_APIURL + "onboardingMeeting/", {}, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getActiveMeeting();
            this.getTableData();
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          });
    },
    getConfigurationData() {
      axios.get(process.env.VUE_APP_APIURL + "dailyCellConfigurations/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.cellConfigurations = response.data;
          });
    },
    createConfiguration() {
      axios.post(process.env.VUE_APP_APIURL + "dailyCellConfiguration/", this.itemToCreate, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.itemToCreate = {
              operator: null,
              priority: null,
              cellName: null,
              action: null,
              onCharge: null,
              critical: null,
              goal: null,
              real: null
            }
            this.getConfigurationData();
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            this.getConfigurationData();
          });
    },
    onCreateRegister(){
      this.$refs.createConfigModal.openDialog();
    },
    onDeleteRegister(item) {
      this.itemToDelete = item;
      this.$refs.deleteConfigModal.openDialog();
    },
    confirmDelete() {
      axios.delete(process.env.VUE_APP_APIURL + "dailyCellConfiguration/?id=" + this.itemToDelete.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getConfigurationData();
            this.showSnackbar("success", "Registro eliminado correctamente");
            this.itemToDelete = null;
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          });
    },
    onEditRegister(item) {
      this.itemToEdit = item;
      this.$refs.editConfigModal.openDialog();
    },
    confirmEdit() {
      axios.put(process.env.VUE_APP_APIURL + "dailyCellConfiguration/?id=" + this.itemToEdit.id, this.itemToEdit,{headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getConfigurationData();
            this.showSnackbar("success", "Registro actualizado correctamente");
            this.itemToEdit = null;
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            this.getConfigurationData();
          });
    },
  },
  mounted() {
    this.getTableData();
    this.getActiveMeeting();
    this.getConfigurationData();
  }
}
</script>

<style scoped>

</style>