<template>
  <v-card>
    <v-card-title primary-title>
      {{ cellName }}
    </v-card-title>
    <slot name="body">

    </slot>
  </v-card>
</template>

<script>

export default {
    props: {
        cellName: {
            type: String,
            default: () => ""
        },
    },
}
</script>

<style>

</style>