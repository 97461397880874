<template>
  <v-container class="d-flex justify-end flex-column">
    <v-subheader style="font-size: 24px" class="mb-4 mt-4">Criterios a evaluar en el GEMBA</v-subheader>
    <deletable-table :headers="headers"
                     :items="items"
                     btn-text="Agregar Problema"
                     deletable
                     @delete="confirmDelete"
                     @create="onCreateRegister"
    >
    </deletable-table>

    <dialog-form title="¿Estas Seguro(a)?" ref="deleteModal" @accept="deleteRegister">
      <template v-if="itemToDelete" v-slot:body>
        <v-container>
          <p class="m-4">
            Estas a punto de eliminar el criterio de evaluación "{{itemToDelete.point}}" del listado de puntos a evaluar.
          </p>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form title="Creación de nuevo criterio de evaluación" ref="createRegisterModal" @accept="createRegister">
      <template v-slot:form>
        <v-container class="m-4">
          <v-text-field
            v-model="itemToCreate"
            label="Criterio de aceptación"
            outlined
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import DeletableTable from "@/components/DeletableTable";
import DialogForm from "@/components/DialogForm";

export default {
  name: "AcceptanceCriteria",
  inject: ["showSnackbar"],
  components: {
    DeletableTable,
    DialogForm,
  },
  data() {
    return {
      headers: [
          { text: 'Puntos', value: 'point', sortable: false }
      ],
      items: [],

      itemToDelete: null,
      itemToCreate: null
    }
  },
  methods: {
    getTableData() {
      // Este metodo traerá la info del API
      this.items = [
         {
            id: 1,
            point: 'Se encuentra en orden',
         },
         {
            id: 2,
            point: 'Limpieza',
         },
         {
            id: 3,
            point: 'Calidad en el mantenimiento de equipo',
         },
      ];
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.$refs.deleteModal.openDialog();
    },
    deleteRegister() {
      // Este metodo va a mandar un DELETE al API
      this.showSnackbar("success", "Registro eliminado correctamente");
      this.getTableData();
    },
    onCreateRegister(){
      this.$refs.createRegisterModal.openDialog();
    },
    createRegister() {
      // Este metodo va a mandar un POST al API
      if(this.isValidInput(this.itemToCreate)) {
        this.showSnackbar("success", "Registro creado correctamente");
        this.getTableData();
      }
      else {
        this.showSnackbar("error", "El criterio no puede estar vacío");
      }

      this.itemToCreate = null;
    },
    isValidInput(item) {
      return !(item === null || item.match(/^ *$/) !== null);
    }
  },
  mounted() {
    this.getTableData();
  }
}
</script>

<style scoped>

</style>