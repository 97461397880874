<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{tableTitle}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn v-if="showBtn" outlined color="primary" @click="onButtonClick">
            {{btnText}}
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "TitleAndActionTable",
  computed: {
  },
  data() {
    return {
    }
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    tableTitle: {
      type: String,
      required: true
    },
    btnText: {
      type: String,
      required: false
    },
    showBtn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onButtonClick() {
      this.$emit("buttonClick");
    }
  }
}
</script>


<style scoped>

</style>