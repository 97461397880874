<template>
  <div>
    <v-data-table
      :headers="realHeaders"
      :items="items"
      hide-default-footer
      item-key="id"
      class="elevation-1"
      :items-per-page="-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
            v-if="editable"
            small
            class="mr-2"
            @click="$emit('edit', item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            v-if="deletable"
            small
            @click="$emit('delete', item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-row v-if="btnText" class="mt-3">
      <v-col class="d-flex justify-end">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="$emit('create')"
        >
          {{ btnText }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DeletableTable",
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    btnText: {
      type: String,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    deletable: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      realHeaders: [],
    }
  },
  mounted() {
    this.realHeaders = [... this.headers];

    if(this.editable || this.deletable) {
      this.realHeaders.push({ text: 'Acciones', value: 'actions', sortable: false, width: '10%'});
    }
  }
}
</script>

<style scoped>

</style>