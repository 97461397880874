import { render, staticRenderFns } from "./JobsAdmin.vue?vue&type=template&id=3f8cb481&scoped=true"
import script from "./JobsAdmin.vue?vue&type=script&lang=js"
export * from "./JobsAdmin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f8cb481",
  null
  
)

export default component.exports