<template>
  <v-container class="mt-3">
    <v-row>
      <v-col>
        <h2>
          Cargar planeación
        </h2>
      </v-col>
    </v-row>

    <v-row dense justify="end">
      <v-col>
        <v-file-input
            show-size
            v-model="masterFile"
            placeholder="Master methods"
            accept=".xlsx, .xls"
        ></v-file-input>
      </v-col>
    </v-row>

    <v-row dense justify="end">
      <v-col>
        <v-file-input
            show-size
            v-model="jobsFile"
            placeholder="Reporte de despacho prioritario"
            accept=".xlsx, .xls"
        ></v-file-input>
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col>
        <div class="d-flex justify-end align-content-end">
          <v-btn color="primary" bottom @click="postFiles">
            Guardar
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
        ></v-progress-linear>
      </v-col>
    </v-row>

    <v-row>
      <v-lazy
      min-height="100"
      :options="{ threshold: .25}"
      :style="{ width: '100%' }"
      transition="fade-transition"
      :value="false">
      <v-col>
        <div v-for="cell in cells" class="mt-4">
          <info-table :headers="headers" :items="cell.items" :title="cell.name"/>
        </div>
      </v-col>
      </v-lazy>
    </v-row>
  </v-container>
</template>

<script>
import InfoTable from "@/components/Tables/InfoTable";
import axios from "axios";

export default {
  name: "Planning",
  inject: ["showSnackbar"],
  components: {
    InfoTable
  },
  data() {
    return {
      headers: [
        {text: "Job", value: "Job Number"},
        {text: "Part", value: "Part Number"},
        {text: "Start Date", value: "Start"},
        {text: "Due Date", value: "Due"},
      ],
      cells: [],
      loading: false,
      masterFile: null,
      jobsFile: null,
      isActive: false
    }
  },
  methods: {
    postFiles() {
      if (this.filesAreValid()) {
        this.loading = true;
        this.cells = null;
        var bodyFormData = new FormData();
        bodyFormData.append('master', this.masterFile);
        bodyFormData.append('jobs', this.jobsFile);

        axios.post(process.env.VUE_APP_APIURL + "planning/", bodyFormData,
            {
              headers: {
                "Authorization": `Bearer ${this.$store.getters["auth/token"]}`,
                "Content-Type": "multipart/form-data"
              }
            })
            .then(response => {
              this.loading = false;
              this.showSnackbar("success", "Información guardada correctamente");
              this.cells = response.data;
              this.jobsFile = null;
              this.masterFile = null;
            })
            .catch(error => {
              this.loading = false;
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            });
      } else {
        this.showSnackbar("error", "Es necesario seleccionar los archivos.");
      }
    },
    filesAreValid() {
      return this.masterFile && this.jobsFile;
    }
  }
}
</script>

<style scoped>

</style>