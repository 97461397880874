<template>
  <v-container class="d-flex justify-end flex-column">
    <v-subheader style="font-size: 24px" class="mb-4 mt-4">Administrar usuarios</v-subheader>
    <deletable-table :headers="headers"
                     :items="items"
                     btn-text="Agregar Usuario"
                     deletable
                     @delete="confirmDelete"
                     @create="onCreateRegister"
    >
    </deletable-table>

    <dialog-form title="¿Estas Seguro(a)?" ref="deleteModal" @accept="deleteRegister">
      <template v-if="itemToDelete" v-slot:body>
        <v-container>
          <p class="m-4">
            Estas a punto de eliminar al usuario "{{itemToDelete.username}}".
          </p>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form title="Creación de nuevo usuario" ref="createRegisterModal" @accept="createRegister" need-close>
      <template v-slot:form>
        <v-container class="m-4">
          <v-text-field
            v-model="itemToCreate.username"
            label="Nombre de usuario"
            outlined
          ></v-text-field>
          <v-select
            v-model="itemToCreate.type"
            :items="userTypes"
            label="Rol"
            outlined
          ></v-select>
          <v-select
            v-if="isProductionType"
            v-model="itemToCreate.cell"
            :items="cells"
            label="Celda"
            item-value="id"
            item-text="name"
            outlined
          ></v-select>
          <v-text-field
            v-model="itemToCreate.password"
            type="password"
            label="Contraseña"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="itemToCreate.confirm_password"
            type="password"
            label="Confirmar Contraseña"
            outlined
            @keyup.enter="createRegister"
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import DialogForm from "@/components/DialogForm";
import DeletableTable from "@/components/DeletableTable";
import {UserTypeEnum} from "@/enums";
import axios from "axios";

export default {
  name: "Users",
  inject: ["showSnackbar"],
  computed: {
    isProductionType(){
      return this.itemToCreate.type === UserTypeEnum.PRODUCTION;
    }
  },
  components: {
    DeletableTable,
    DialogForm,
  },
  data() {
    return {
      headers: [
          { text: 'Nombre de usuario', value: 'username', sortable: false },
          { text: 'Rol', value: 'type', sortable: false },
      ],
      items: [],
      userTypes: Object.values(UserTypeEnum),
      cells: [],
      itemToDelete: null,
      itemToCreate: {
        username: null,
        type: null,
        password: null,
        confirm_password: null,
        cell: null
      }
    }
  },
  methods: {
    getCells() {
      // Este metodo traerá la info del API
      axios.get(process.env.VUE_APP_APIURL + "cells/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.cells = response.data;
          });
    },
    getTableData() {
      // Este metodo traerá la info del API
      axios.get(process.env.VUE_APP_APIURL + "users/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.items = response.data;
          });
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.$refs.deleteModal.openDialog();
    },
    deleteRegister() {
      // Este metodo va a mandar un DELETE al API
      axios.delete(process.env.VUE_APP_APIURL + "users/" + this.itemToDelete.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getTableData();
            this.showSnackbar("success", "Registro eliminado correctamente");
            this.itemToDelete = null;
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          })
    },
    onCreateRegister(){
      this.$refs.createRegisterModal.openDialog();
    },
    createRegister() {
      if(this.validatePasswords()) {
        axios.post(process.env.VUE_APP_APIURL + "users/", this.itemToCreate,
          {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}} )
          .then(response => {
            this.showSnackbar("success", "Registro creado correctamente");
            this.getTableData();

            this.itemToCreate = {
              username: null,
              type: null,
              assigned_lane: null,
              password: null,
              confirm_password: null,
              cell: null
            };
            this.$refs.createRegisterModal.closeDialog();
          })
          .catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          });
      }
      else {
         this.showSnackbar("error", "Las contrasenias no coinciden o tienen una longitud menor a 8 caracteres");
      }
    },
    validatePasswords() {
      return this.itemToCreate.password === this.itemToCreate.confirm_password && this.itemToCreate.password.length > 7;
    },
  },
  mounted() {
    this.getTableData();
    this.getCells();
  }
}
</script>

<style scoped>

</style>