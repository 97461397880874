<template>
    <Bar ref="bar" :data="chartData" :options="chartOptions"></Bar>
</template>

<script>
import { Bar } from "vue-chartjs";

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
);

export default {
    components: { Bar },
    props: {
        dataChartParent: {
            type: Array,
            required: true,
        },
        titleBarChart: {
            type: String | undefined,
            required: true,
        },
        labels: {
            type: Array,
            required: true,
        },
        chartLabel: {
            type: String,
            required: true,
        },
        needColorDiference: {
            type: Object,
            default: () => {
                return {
                    isActive: false,
                    limit: 0,
                };
            },
        },
    },
    computed: {
        chartData() {
            if (this.$props.needColorDiference.isActive) {
                let dataToMap = {};
                let arr = [...this.labels];
                arr.forEach((element, index) => {
                    dataToMap[element] =
                        this.$props.dataChartParent.data[index];
                });
                dataToMap = Object.fromEntries(
                    Object.entries(dataToMap).sort(([, a], [, b]) => b - a)
                );

                var labelsInDataMap = Object.keys(dataToMap);
                var dataInDataMap = Object.values(dataToMap);
                var borderColor = "#000000";
                var backgroundColor = dataInDataMap.map((value) => value > this.$props.needColorDiference.limit ? "#55ed62" : "#ed5555" );

                return {
                    labels: arr,
                    datasets: [
                        {
                            labels: labelsInDataMap,
                            data: dataInDataMap,
                            borderColor: borderColor,
                            backgroundColor: backgroundColor,
                        },
                    ],
                };
            }

            return {
                labels: this.labels,
                datasets: [
                    {
                        label: this.chartLabel,
                        data: this.$props.dataChartParent,
                        borderColor: "#36A2EB",
                        backgroundColor: "#332FD0",
                    },
                ],
            };
        },
        chartOptions() {
            const columnItem = (activePoint) => {
                this.$emit("giveIndexColumn", activePoint);
            };

            return {
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: this.$props.titleBarChart,
                    },
                },
                interaction: {
                    mode: "index",
                },
                onClick: function (_, item) {
                    columnItem(item.index);
                },
            };
        },
        chartInstance() {
            return this.$refs.bar.chart;
        },
    },
    methods: {
        sortDataAndLabelsWithColor() {
            const isOutLimitColor = "#332FD0";
            const isInLimitColor = "rgb(255, 99, 132)";

            return ["#332FD0", "rgb(255, 99, 132)", "rgb(255, 99, 132)"];
        },
    },
};
</script>
