import auth from "@/store/modules/auth";
import hour from "@/store/modules/hour";
import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    // módulo para login & logout
    auth: auth,
    hour: hour
  },
  plugins: [createPersistedState()],
});
