<template>
  <v-container class="d-flex justify-end flex-column">
    <v-subheader style="font-size: 24px" class="mb-4 mt-4">Dashboard de Onboarding</v-subheader>
    <v-row>
      <v-col sm="12" md="6" lg="4">
        <title-and-actions-table
            table-title="Celdas críticas"
            :items="criticalCells"
            :headers="criticalCellsHeaders"
        >
        </title-and-actions-table>
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <title-and-actions-table
            table-title="Progreso de celdas"
            :items="cellConfigurations"
            :headers="goalsCellsHeaders"
        >
        </title-and-actions-table>
      </v-col>

      <v-col sm="12" md="12" lg="4">
        <title-and-action-table
            table-title="Onboarding de hoy"
            :items="items"
            :headers="headers"
        >
        </title-and-action-table>
      </v-col>

    </v-row>

    <v-row>
      <v-col  class="mt-5">
        <title-and-actions-table
            table-title="Configuración de celdas"
            :items="cellConfigurations"
            :headers="cellConfigurationHeaders"
        >
        </title-and-actions-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import DeletableTable from "@/components/DeletableTable";
import DialogForm from "@/components/DialogForm";
import axios from "axios";
import TitleAndActionTable from "@/components/TitleAndActionTable.vue";
import TitleAndActionsTable from "@/components/TitleAndActionsTable.vue";
import {PriorityEnum} from "@/enums";

export default {
  name: "OnboardingDashboard",
  inject: ["showSnackbar"],
  components: {
    DeletableTable,
    DialogForm,
    TitleAndActionTable,
    TitleAndActionsTable
  },
  data() {
    return {
      headers: [
          { text: 'Usuario', value: 'username', sortable: false },
          { text: 'Tipo de usuario', value: 'type', sortable: false },
          { text: 'Inicio de la reunión', value: 'start_datetime', sortable: false },
          { text: 'Fin de la reunión', value: 'end_datetime', sortable: false },
      ],
      items: [],
      cellConfigurations: [],
      criticalCellsHeaders: [
          //"operator", "priority", "cellName", "action", "onCharge", "critical", "date", "goal", "real"
          { text: 'Celda', value: 'cellName', sortable: true },
          { text: 'Operador', value: 'operator', sortable: false },
          { text: 'Encargado', value: 'onCharge', sortable: false },
          { text: 'Acción', value: 'action', sortable: false },
      ],
      goalsCellsHeaders: [
          //"operator", "priority", "cellName", "action", "onCharge", "critical", "date", "goal", "real"
          { text: 'Celda', value: 'cellName', sortable: true },
          { text: 'Operador', value: 'operator', sortable: false },
          { text: 'Meta', value: 'goal', sortable: true },
          { text: 'Realizado', value: 'real', sortable: true },
      ],
      cellConfigurationHeaders: [
          //"operator", "priority", "cellName", "action", "onCharge", "critical", "date", "goal", "real"
          { text: 'Celda', value: 'cellName', sortable: true },
          { text: 'Operador', value: 'operator', sortable: false },
          { text: 'Prioridad', value: 'priority', sortable: true },
          { text: 'Encargado', value: 'onCharge', sortable: false },
          { text: 'Meta', value: 'goal', sortable: true },
          { text: 'Realizado', value: 'real', sortable: true },
          { text: 'Es crítica', value: 'critical', sortable: true },
          { text: 'Acción', value: 'action', sortable: false },
      ],
    }
  },
  methods: {
    getTableData() {
      axios.get(process.env.VUE_APP_APIURL + "onboardingMeetings/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.items = response.data;

            this.items.forEach(value => {
              let temporaryStart = new Date(value.start_datetime);
              value.start_datetime = `${temporaryStart.getDate() < 10 ? '0' + temporaryStart.getDate() : temporaryStart.getDate() }/${temporaryStart.getMonth()}/${temporaryStart.getFullYear()} ${temporaryStart.getHours()}:${temporaryStart.getMinutes() < 10 ? '0' + temporaryStart.getMinutes() : temporaryStart.getMinutes()}`
              if (value.end_datetime) {
                let temporaryEnd = new Date(value.end_datetime);
                value.end_datetime = `${temporaryStart.getDate() < 10 ? '0' + temporaryEnd.getDate() : temporaryEnd.getDate() }/${temporaryEnd.getMonth()}/${temporaryEnd.getFullYear()} ${temporaryEnd.getHours()}:${temporaryEnd.getMinutes() < 10 ? '0' + temporaryEnd.getMinutes() : temporaryEnd.getMinutes()}`
              }
            })
          });
    },
    getConfigurationData() {
      axios.get(process.env.VUE_APP_APIURL + "dailyCellConfigurations/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.cellConfigurations = response.data;
          });
    },
  },
  computed: {
    criticalCells() {
      return this.cellConfigurations.filter(value => {
        return value.critical;
      })
    }
  },
  mounted() {
    this.getTableData();
    this.getConfigurationData();
  }
}
</script>

<style scoped>

</style>