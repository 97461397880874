<template>
<v-container class="mt-3">
    <v-row>
      <v-col>
        <v-select
            v-model="selectedCell"
            label="Celda a consultar"
            :items="cells"
            item-value="id"
            item-text="name"
            @change="onSelectedCellChange"
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="selectedCell">
      <v-row>
        <v-col sm="2" cols="12">
          <v-text-field
              label="Operador"
              hide-details="auto"
              v-model="employeeNumber"
              @change="updateEmployeeNumber"
          ></v-text-field>
        </v-col>
        <v-col sm="2" cols="12">
          <v-text-field
              label="# de parte"
              hide-details="auto"
              disabled
              v-model="jobInfo.part_number"
          ></v-text-field>
        </v-col>
        <v-col sm="2" cols="12">
          <v-select
              :items="jobs"
              label="Job"
              v-model="selectedJob"
              @change="onSelectedJobChange"
              item-value="id"
              item-text="number"
          ></v-select>
        </v-col>
        <v-col sm="2" cols="12">
          <v-text-field
              label="Fecha de entrega"
              hide-details="auto"
              v-model="jobInfo.due_date"
              disabled
          ></v-text-field>
        </v-col>
        <v-col sm="2" cols="12">
          <v-text-field
              label="Rate"
              hide-details="auto"
              v-model="jobInfo.rate"
              disabled
          ></v-text-field>
        </v-col>
        <v-col sm="2" cols="12">
          <v-text-field
              label="Número de serie"
              hide-details="auto"
              v-model="serial"
              @keydown.enter="registerPiece"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>

      </v-row>

      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col class="noPadding">
              <div class="d-flex justify-end align-content-end">
                <v-btn outlined color="error" @click="deleteScrapRegister">
                  Borrar registro de scrap
                </v-btn>
              </div>
            </v-col>
            <v-col class="noPadding">
              <div class="d-flex justify-end align-content-end">
                <v-btn outlined color="error" @click="deletePieceRegister">
                  Borrar registro de pieza
                </v-btn>
              </div>
            </v-col>
            <v-col class="noPadding">
              <div class="d-flex justify-end align-content-end">
                <v-btn color="primary" @click="registerPiece">
                  Registrar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>


      <v-row>
        <v-col>
          <hx-h-table :headers="headers" :items="computedHourData" :notes="notes" @noteUpdated="updateNote"></hx-h-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div>
            <v-btn color="secondary" @click="onRegisterSetup">
              Iniciar Setup
            </v-btn>
          </div>
        </v-col>
        <v-col>
          <div class="d-flex justify-end align-content-end">
            <v-btn color="primary" @click="onRegisterPause">
              Solicitar pausa
            </v-btn>
          </div>
        </v-col>
      </v-row>

    </div>

    <dialog-form :need-close="!isActivePause" :title="isActivePause ? 'Pausa activa':'Iniciar Pausa'" is-form ref="registerPauseForm" @accept="registerPause">
      <template v-slot:form>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  label="Hora actual"
                  disabled
                  v-model="currentHour"
              ></v-text-field>
            </v-col>
          </v-row>
          <template v-if="isActivePause">
            <v-row>
              <v-col>
                <h2>Finalizar pausa</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Hora de inicio"
                    disabled
                    v-model="pauseStart"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col>
                <v-select
                    v-model="pauseType"
                    label="Razón de la pausa"
                    :items="notes"
                    item-value="id"
                    item-text="name"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Número de empleado"
                    v-model="employeeNumber">
                </v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </template>
    </dialog-form>
    <dialog-form :need-close="!isActiveSetup" :title="isActiveSetup ? 'Setup activo':'Iniciar Setup'" is-form ref="registerSetupForm" @accept="registerSetup">
      <template v-slot:form>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  label="Hora actual"
                  disabled
                  v-model="currentHour"
              ></v-text-field>
            </v-col>
          </v-row>
          <template v-if="isActiveSetup">
            <v-row>
              <v-col>
                <h2>Finalizar Setup</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Hora de inicio"
                    disabled
                    v-model="pauseStart"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col>
                <v-text-field
                    label="Número de empleado"
                    v-model="employeeNumber">
                </v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import HxHTable from "@/components/Tables/HxHTable";
import DialogForm from "@/components/DialogForm";
import axios from "axios";
import {PieceRegisterTypeEnum, SerialTypeEnum} from "@/enums";

export default {
  name: "AdminHxH",
  inject: ["showSnackbar"],
  components: {
    HxHTable,
    DialogForm
  },
  computed: {
    computedTodaysDate() {
      return this.todaysDate;
    },
    currentHour() {
      return this.computedTodaysDate.toTimeString();
    },
    computedHourData() {
      let info = [...this.hourData]
      let acummReal = 0;
      let acummPlan = 0;

      for (let i = 0; i < info.length; i++) {
        acummReal += info[i].pieces ? info[i].pieces : 0;
        acummPlan += info[i].planned ? info[i].planned : 0;

        info[i].acummReal = Number(Number(acummReal).toFixed(2));
        info[i].acummPlan = Number(Number(acummPlan).toFixed(2));
      }

      return info;
    }
  },
  data() {
    return {
      employeeNumber: this.$store.getters["hour/employee_number"],
      pauseType: null,
      notes: [],
      selectedJob: null,
      jobs: [],
      jobInfo: {
        part_number: null,
        client: null,
        id: null,
        due_date: null,
        rate: null
      },
      serial: null,
      headers: [
        {text: "De", value: "start"},
        {text: "A", value: "end"},
        {text: "Plan", value: "planned"},
        {text: "Plan Acumulado", value: "acummPlan"},
        {text: "Real", value: "pieces"},
        {text: "Real Acumulado", value: "acummReal"},
        {text: "Scrap", value: "scrap"},
        {text: "Nota", value: "note"},
      ],
      hourData: [],
      isActivePause: false,
      isActiveSetup: false,
      normal: PieceRegisterTypeEnum.NORMAL,
      pauseStart: null,
      todaysDate: new Date(),
      selectedCell: null,
      cells: [],
    }
  },
  methods: {
    getJobs() {
      axios.get(process.env.VUE_APP_APIURL + "job/" + this.selectedCell, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {

            this.jobs = response.data;
          });
    },
    onSelectedJobChange() {
      this.jobInfo = this.jobs.find(job => job.id === this.selectedJob);
      let date = new Date(Date.parse(this.jobInfo.due_date));
      this.jobInfo.due_date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      this.updatePlanned();
      this.checkPauseOrSetup();
      this.getHourData();
    },
    getHourData() {
      if(!this.selectedCell) {
        this.showSnackbar("error", "Debes seleccionar una celda.");
        return;
      }
      let url = process.env.VUE_APP_APIURL + "hxhSchedule/" + this.selectedCell
      if(this.selectedJob) {
        url += "?job=" + this.selectedJob
      }
      axios.get( url , {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.hourData = response.data;
            this.updatePlanned();
          });
    },
    onRegisterPause() {
      if (!this.jobInfo.id) {
        this.showSnackbar("error", "Debes seleccionar un job.");
        return;
      }
      axios.get(process.env.VUE_APP_APIURL + "pause/" + this.jobInfo.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.$refs.registerPauseForm.openDialog();
            this.isActivePause = response.data.isActive;
          });
    },
    onRegisterSetup() {
      if (!this.jobInfo.id) {
        this.showSnackbar("error", "Debes seleccionar un job.");
        return;
      }
      axios.get(process.env.VUE_APP_APIURL + "setup/" + this.jobInfo.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.$refs.registerSetupForm.openDialog();
            this.isActiveSetup = response.data.isActive;
          });
    },
    registerPause() {
      if (this.validateData() || this.isActivePause) {
        axios.post(process.env.VUE_APP_APIURL + "pause/" + this.jobInfo.id, {
              employeeNumber: this.employeeNumber,
              description: this.pauseType
            },
            {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.showSnackbar("success", "Pausa registrada correctamente");
              this.isActivePause = false;
              this.checkPauseOrSetup();
            })
            .catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            });
      }
    },
    registerSetup() {
      if ((this.jobInfo.id && this.employeeNumber) || this.isActiveSetup) {
        axios.post(process.env.VUE_APP_APIURL + "setup/" + this.jobInfo.id, {
              employeeNumber: this.employeeNumber,
              description: this.pauseType
            },
            {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.showSnackbar("success", "Setup registrado correctamente");
              this.isActiveSetup = false;
              this.checkPauseOrSetup();
            })
            .catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            });
      }
      else{
        this.showSnackbar("error", "Necesitas llenar los datos y seleccionar un job.");
      }
    },
    validateData() {
      if (!this.jobInfo.id) {
        this.showSnackbar("error", "Debes seleccionar un job.");
        return false;
      } else if (!(this.pauseType && this.employeeNumber)) {
        this.showSnackbar("error", "Necesitas llenar los datos.");
      }
      return this.pauseType && this.employeeNumber;
    },
    getNotes() {
      axios.get(process.env.VUE_APP_APIURL + "notes/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.notes = response.data;
          });
    },
    updatePlanned() {
      this.hourData = [...this.hourData.map((value, index) => {
        let today = new Date();
        let startDate = new Date(`${today.getFullYear()}-${today.getMonth() < 9 ? "0" : ""}${today.getMonth() + 1}-${today.getDate() <= 9 ? "0" : ""}${today.getDate()}T${value.start}`)
        let endDate = new Date(`${today.getFullYear()}-${today.getMonth() < 9 ? "0" : ""}${today.getMonth() + 1}-${today.getDate() <= 9 ? "0" : ""}${today.getDate()}T${value.end}`)


        if (endDate.getHours() === 0 && endDate.getMinutes() === 0 && endDate.getSeconds() === 0) {
          endDate = new Date(`${today.getFullYear()}-${today.getMonth() < 9 ? "0" : ""}${today.getMonth() + 1}-${today.getDate() <= 9 ? "0" : ""}${today.getDate()}T23:59:59`)
        }

        let data = {
          start: value.start,
          end: value.end,
          pieces: value.pieces,
          note: value.note,
          scrap: value.scrap
        };

        // esto es para las pausas de comida, pero no funciona con minutos distintos a 60
        let minutes = this.getMinuteDifferenceBetweenTwoDates(startDate, endDate);
        let hoursWithBreaks = [];
        let foodBreaks = {};
        if (minutes === 60) {
          hoursWithBreaks = [7, 12, 13, 19]
          foodBreaks = {
            7: 20,
            12: 20,
            13: 10,
            19: 30
          }
        }

        if(hoursWithBreaks.includes(startDate.getHours())) {
          minutes = minutes - foodBreaks[startDate.getHours()]
        }

        data.planned = value.planned ?
            value.planned :
            (this.jobInfo.rate * minutes) / 60;

        // si es la primera hora del turno, el rate es el 80%
        if(index === 0) {
          data.planned *= 0.8
        }

        data.planned = Number(Number(data.planned).toFixed(2));

        return data;
      })];
    },
    getMinuteDifferenceBetweenTwoDates(start, end) {
      let diff = Number((start.getTime() - end.getTime()) / 1000);
      diff /= 60;
      return Math.abs(Math.round(diff));
    },
    registerPiece() {
      if (!this.jobInfo.id) {
        this.showSnackbar("error", "Es necesario seleccionar un job.");
        return;
      }

      let uri = "";
      let message = "";

      if(this.serial.toLowerCase() === SerialTypeEnum.NORMAL.toLowerCase()){
        uri = "pieceRegister/";
        message = "Pieza registrada correctamente";
      }
      else if(this.serial.toLowerCase() === SerialTypeEnum.SCRAP.toLowerCase()){
        uri = "scrapRegister/"
        message = "Pieza registrada como scrap correctamente"
      }
      else {
        this.showSnackbar("error", "Número de serie no pertenece a los permitidos 'buenas/malas'.");
        return;
      }

      axios.get(process.env.VUE_APP_APIURL + "setup/" + this.jobInfo.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
      .then(response => {
        this.isActiveSetup = response.data.isActive;
        axios.get(process.env.VUE_APP_APIURL + "pause/" + this.jobInfo.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.isActivePause = response.data.isActive;
            if (this.validatePieceData() && !this.isActivePause && !this.isActiveSetup) {
              axios.post(process.env.VUE_APP_APIURL + uri, {
                    employeeNumber: this.employeeNumber,
                    serial: this.serial,
                    jobNumber: this.jobs.find(job => job.id === this.selectedJob).number,
                    partNumber: this.jobInfo.part_number,
                    cellId: this.selectedCell,
                  },
                  {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
                  .then(response => {
                    this.showSnackbar("success", message);
                    this.serial = null;
                    this.getHourData();
                  })
                  .catch(error => {
                    this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
                  });
            } else {
              if (!this.validatePieceData()) {
                this.showSnackbar("error", "Es necesario llenar todos los campos.");
              }
              else if(this.isActiveSetup) {
                this.showSnackbar("error", "Tienes un setup activo.");
              }
              else if (this.isActivePause) {
                this.showSnackbar("error", "Tienes una pausa activa.");
              }

            }
          });
      });

    },
    validatePieceData() {
      return this.serial && this.selectedJob && this.employeeNumber && this.jobInfo.part_number;
    },
    updateNote(data) {
      axios.put(process.env.VUE_APP_APIURL + `noteJob/${data.start}/${data.end}/${this.selectedCell}`, {
            note: data.note
          },
          {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.showSnackbar("success", "Nota registrada correctamente");
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          });
    },
    checkPauseOrSetup() {
      axios.get(process.env.VUE_APP_APIURL + "setup/" + this.jobInfo.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
      .then(response => {
        this.isActiveSetup = response.data.isActive;
        if(this.isActiveSetup) {
          this.pauseStart = response.data.start_date;
          this.$refs.registerSetupForm.openDialog();
        }
        else {
          axios.get(process.env.VUE_APP_APIURL + "pause/" + this.jobInfo.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.isActivePause = response.data.isActive;

            if(this.isActivePause) {
              this.pauseStart = response.data.start_date;
              this.$refs.registerPauseForm.openDialog();
            }

          });
        }
      });
    },
    updateEmployeeNumber() {
      this.$store.dispatch("hour/setEmployeeNumber", this.employeeNumber);
    },
    deletePieceRegister() {
      if (!this.jobInfo.id) {
        this.showSnackbar("error", "Es necesario seleccionar un job.");
        return;
      }

      axios.delete(process.env.VUE_APP_APIURL + "pieceRegister/", {
                    data: {
                      employeeNumber: this.employeeNumber,
                      jobNumber: this.jobs.find(job => job.id === this.selectedJob).number,
                      partNumber: this.jobInfo.part_number,
                      cellId: this.selectedCell,
                    },
                    headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}
                  })
                  .then(response => {
                    this.showSnackbar("success", "Pieza eliminada correctamente.");
                    this.getHourData();
                  })
                  .catch(error => {
                    this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
                  })
    },
    deleteScrapRegister() {
      if (!this.jobInfo.id) {
        this.showSnackbar("error", "Es necesario seleccionar un job.");
        return;
      }

      axios.delete(process.env.VUE_APP_APIURL + "scrapRegister/", {
                    data: {
                      employeeNumber: this.employeeNumber,
                      jobNumber: this.jobs.find(job => job.id === this.selectedJob).number,
                      partNumber: this.jobInfo.part_number,
                      cellId: this.selectedCell,
                    },
                    headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}
                  })
                  .then(response => {
                    this.showSnackbar("success", "Registro de scrap eliminado correctamente.");
                    this.getHourData();
                  })
                  .catch(error => {
                    this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
                  })
    },
    getCells() {
      axios.get(process.env.VUE_APP_APIURL + "cells/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.cells = response.data;
          });
    },
    onSelectedCellChange() {
      this.getJobs();
      this.getHourData();
    }

  },
  mounted() {
    setInterval(() => {
      this.todaysDate = new Date();
    }, 1000);
    setInterval(() => {
      this.getJobs();
    }, 100000);
    this.getJobs();
    this.getNotes();
    this.getCells();
  }
}
</script>

<style scoped>
.noPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>