<template>
    <v-container class="d-flex justify-end flex-column">
        <v-subheader style="font-size: 24px" class="mb-4 mt-4"
            >Administrador de Jobs</v-subheader
        >

        <v-row>
          <v-col>
            <v-text-field
                label="Número de job"
                hide-details="auto"
                v-model="jobNumber"
                @keydown.enter="getCellsData"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col>
            <div class="d-flex justify-end align-content-end">
              <v-btn color="primary" bottom @click="getCellsData">
                Buscar
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <div v-for="item in cellsData" :key="item.name" style="display: block">
            <cell class="mt-2" :cell-name="item.name">
                <template v-slot:body>
                    <cell-table-job :data-table="item.items" @edit="onEdit" @delete="onDelete"></cell-table-job>
                </template>
            </cell>
        </div>
      
      <dialog-form title="Editar Job" ref="editJobInCell" @accept="patchJobCell" need-close>
        <template v-slot:form>
          <v-container class="m-4">
            <v-datetime-picker
              v-model="itemToEdit.start_date"
              label="Fecha de inicio"
              outlined
              clear-text="Borrar"
              ok-text="Guardar"
            >
              <template v-slot:dateIcon>
                <v-icon>
                  mdi-calendar
                </v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>
                  mdi-clock
                </v-icon>
              </template>
            </v-datetime-picker>
            <v-datetime-picker
              v-model="itemToEdit.due_date"
              type="text"
              label="Fecha de finalizacion"
              outlined
              clear-text="Borrar"
              ok-text="Guardar"
            >
              <template v-slot:dateIcon>
                <v-icon>
                  mdi-calendar
                </v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>
                  mdi-clock
                </v-icon>
              </template>
            </v-datetime-picker>
            <v-text-field
              v-model="itemToEdit.total_pieces"
              type="number"
              label="Piezas totales"
              outlined
            ></v-text-field>
            <v-select
              v-model="itemToEdit.cellId"
              :items="cells"
              item-text="name"
              item-value="id"
              label="Id de Celda"
              outlined
              @keypress.enter="patchJobCell"
            ></v-select>
          </v-container>
        </template>
      </dialog-form> 

      <dialog-form title="Eliminar Job" ref="deleteJobInCell" @accept="deleteJobCell" need-close>
        <template v-slot:form>
          <p class="ml-4 mt-4">
            Estas a punto de eliminar al job "{{itemToDelete.jobId}}".
          </p>
        </template>
      </dialog-form>
    </v-container>
</template>

<script>
import DeletableTable from "@/components/DeletableTable";
import DialogForm from "@/components/DialogForm";
import axios from "axios";
import Cell from '../components/Cell.vue';
import CellTable from '../components/Tables/CellTable.vue';
import CellTableJob from '../components/Tables/CellTableJob.vue';

export default {
    name: "CellsAdmin",
    inject: ["showSnackbar"],
    components: {
        DeletableTable,
        DialogForm,
        Cell,
        CellTable,
        CellTableJob,
    },
    data() {
        return {
            itemToDelete: {
                start_date: null,
                due_date: null,
                total_pieces: null,
                cellId: null,
                jobId: null
            },
            itemToEdit: {
                start_date: null,
                due_date: null,
                total_pieces: null,
                cellId: null,
                jobId: null
            },
            cellsData: [],
            cells: [],
            jobNumber: null
        };
    },
    methods: {
          getCells() {
            // Este metodo traerá la info del API
            axios.get(process.env.VUE_APP_APIURL + "cells/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
                .then(response => {
                  this.cells = response.data;
                });
          },
          getCellsData() {
            let uri = process.env.VUE_APP_APIURL + "jobAdmin/";

            if(this.jobNumber) {
              uri += `?job_number=${this.jobNumber}`
            }

            axios.get(uri, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
                .then(response => {
                  this.cellsData = response.data;
                  this.jobNumber = null;
                });

          },
          patchJobCell(){
            //Este es el metodo que va a mandar un PATCH al API
            const dataToSend = this.itemToEdit
            dataToSend.start_date = `${dataToSend.start_date.getFullYear()}-${dataToSend.start_date.getMonth() + 1}-${dataToSend.start_date.getDate()} ${dataToSend.start_date.getHours()}:${dataToSend.start_date.getMinutes()}:${dataToSend.start_date.getSeconds()}`
            dataToSend.due_date = `${dataToSend.due_date.getFullYear()}-${dataToSend.due_date.getMonth() + 1}-${dataToSend.due_date.getDate()} ${dataToSend.due_date.getHours()}:${dataToSend.due_date.getMinutes()}:${dataToSend.due_date.getSeconds()}`
            axios.patch(process.env.VUE_APP_APIURL + `jobAdmin/${this.itemToEdit.jobId}`, dataToSend, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}}).then(response => {
              this.showSnackbar("success", "Registro editado correctamente");
              this.getCellsData();
            }).catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            })
            this.$refs.editJobInCell.closeDialog();
          }, 
          deleteJobCell(){
            //Este es el metodo para hacer DELETE al API
            axios.delete(process.env.VUE_APP_APIURL + `jobAdmin/${this.itemToDelete.jobId}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}}).then(response => {
              this.showSnackbar("success", "Registro eliminado correctamente");
              this.getCellsData();
            }).catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            })

            this.$refs.deleteJobInCell.closeDialog();
          },
          onEdit(item){
            this.itemToEdit = {
              start_date: new Date(item.start_date),
                due_date: new Date(item.due_date),
                total_pieces: item.total_pieces,
                cellId: item.cell_id,
                jobId: item.id
            }
            this.$refs.editJobInCell.openDialog();
          }, 
          onDelete(item){
            
            this.itemToDelete = {
              start_date: item.start_date,
                due_date: item.due_date,
                total_pieces: item.total_pieces,
                cellId: item.cell_id,
                jobId: item.id
            }
            this.$refs.deleteJobInCell.openDialog();
          },
    },
    mounted() {
        this.getCells();
        this.getCellsData();
    },
};
</script>

<style scoped></style>
