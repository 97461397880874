<template>
    <v-data-table
        :headers="headers"
        :items="dataTable"
        hide-default-footer
        multi-sort
        :sort-by="['employee__name', 'day']"
        :sort-desc="[false, true]"
        :items-per-page="-1"
    >
    </v-data-table>
</template>

<script>
import WeekDaysEnum from "@/enums/modules/WeekDaysEnum"


export default {
    props: {
        dataTable: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            headers: [
                { text: "Empleado", value: "employee__name" },
                { text: "Día", value: "day" },
                { text: "Número de pausas", value: "pauses" },
                { text: "Tiempo total de pausa", value: "hours" },
            ],
        };
    },
  methods: {
  }
};
</script>
