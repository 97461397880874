<template>
    <v-data-table :headers="headers" :items="items" hide-default-footer class="elevation-1" :items-per-page="-1">

      <template v-slot:item.acummReal="{ item }">
        <v-chip
          :color="realColumnColor(item)"
          dark
        >
          {{ item.acummReal }}
        </v-chip>
      </template>

      <template v-slot:item.note="props">
        <v-edit-dialog
            :return-value.sync="props.item.note"
            large
            persistent
            cancel-text="Cancelar"
            save-text="Guardar"
            @save="onSaveNote(props.item)"
            eager
        >
          <div>
            {{
            computedNotes.find(note => note.id === props.item.note) ? computedNotes.find(note => note.id === props.item.note).name : null
            }}
          </div>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Añadir nota
            </div>
            <v-select
                v-model="props.item.note"
                label="Nota"
                single-line
                counter
                autofocus
                :items="notes"
                item-value="id"
                item-text="name"
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
</template>

<script>
export default {
  name: "HxHTable",
  computed: {
    computedNotes() {
      return this.notes;
    },
    realColumnColor() {
      return (item) => {
        const date = new Date();
        const midnightHours = ["00:00:00", "01:00:00", "02:00:00", "03:00:00", "04:00:00", "05:00:00"]
        // No madrugada, meta mayor a acummReal y hora menor a la actual
        if (item.acummReal < item.acummPlan && item.start < this.currentHour && !midnightHours.includes(item.start)) {
          return '#F44336';
        } else if (item.acummReal < item.acummPlan && item.start < this.currentHour && midnightHours.includes(`${('0' + date.getHours()).slice(-2)}:00:00`)) { // Madrugada, horas anteriores a la actual y real menor a meta
          return '#F44336';
        } else if (item.acummReal < item.acummPlan && item.start > "06:00:00" && midnightHours.includes(`${('0' + date.getHours()).slice(-2)}:00:00`)) { // madrugada, horas mayores a la madrugada (ej. 18:00 - 23:00) y meta mayor a acummReal
          return '#F44336';
        } else {
          return '#4CAF50';
        }
      }
    }
  },
  data() {
    return {
      currentHour: `${('0' + new Date().getHours()).slice(-2)}:${new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()}:00`,
    }
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    notes: {
      type: Array,
      required: true
    }
  },
  methods: {
    onSaveNote(item) {
      this.$emit("noteUpdated", {start: item.start, end: item.end, note: item.note});
    }
  }
}
</script>

<style scoped>

</style>