<template>
    <Bar :data="chartData" :options="chartOptions"></Bar>
</template>

<script>
import { Bar } from "vue-chartjs";
import Chart  from "chart.js/auto";
export default {
    components: {
        Bar,
    },
    props: {
        dataChartParent: {
            type: Array,
            required: true,
        },
    },
    computed: {
        chartData() {
            let dataOrdened = [...this.$props.dataChartParent];
            dataOrdened.sort((a, b) => a.time - b.time).reverse();

            let paretoLabels = dataOrdened.map((item) => item.label);
            let dataPts = dataOrdened.map((item) => item.time);

            let totalSum = dataPts.reduce(
                (sum, datapoint) => sum + datapoint,
                0
            );

            let sum = 0;
            let cumulativeDataPoints = dataPts.map(
                ((sum = 0), (n) => (sum += n))
            );

            let percentageDataPoints = cumulativeDataPoints.map((value) => {
                let percentage = (value / totalSum) * 100;
                return parseFloat(percentage).toFixed(1);
            });

            return {
                datasets: [
                    {
                        type: "line",
                        label: "Porcentaje acumulado",
                        data: percentageDataPoints,
                        backgroundColor: "rgba(255, 26, 104, 0.2)",
                        borderColor: "rgba(255, 26, 104, 1)",
                        fill: false,
                        borderWidth: 1,
                        yAxisID: "y",
                    },
                    {
                        type: "bar",
                        label: "Tiempo perdido (horas)",
                        data: dataPts,
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                        borderColor: "rgba(54, 162, 235, 1)",
                        borderWidth: 1,
                        barPercentage: 0.2,
                        categoryPercentage: 1,
                        yAxisID: "y1",
                    },
                ],
                labels: paretoLabels,
            };
        },
        chartOptions() {
            return {
                type: "line",
                responsive: true,
                scales: {
                    y: {
                        type: "linear",
                        position: "left",
                        min: 0,
                        max: 100,
                        ticks: {
                            callback: function (value) {
                                return value + "%";
                            },
                            beginAtZero: true,
                        },
                        title: {
                            display: true,
                            text: "Porcentaje acumulado",
                        },
                    },
                    y1: {
                        position: "right",
                        grid: {
                            display: false,
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                        title: {
                            display: true,
                            text: "Tiempo total perdido",
                        },
                    },
                },
            };
        },
    },
};
</script>

<style></style>
