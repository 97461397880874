export const NoteCategoryEnum = Object.freeze({
    SECURITY: "Seguridad",
    QUALITY: "Calidad",
    ENGINEERING: "Ingeniería",
    MAINTENANCE: "Mantenimiento",
    OPERATIONS: "Operaciones",
    HR: "Recursos Humanos",
    MATERIAL: "Materiales",
  });

export default NoteCategoryEnum;