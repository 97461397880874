<template>
    <v-container>
      <v-expansion-panels v-model="expandedItem" accordion>
        <v-expansion-panel
            v-for="week in weeks"
            :key="week.id"
        >
          <v-expansion-panel-header>{{ week.name }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <chart-table v-if="selectedWeek" :data="data" :headers="headers" :month="month" :week="selectedWeek.id"
                         :year="year" @expandedDay="replicateEvent">
            </chart-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </template>
  
  <script>
  import ChartTable from './ChartTable.vue';
  
  export default {
    components: {
      ChartTable
    },
    props: {
      data: {
        type: Array,
        required: true
      },
      month: {
        type: Number,
        default: new Date().getMonth()
      },
      headers: {
        type: Array,
        default:() => [
          {
            text: "Problema",
            align: "start",
            sortable: false,
            value: "issue__name",
          },
          {
            text: "Tiempo Perdido",
            value: "time",
          },
          {
            text: "Numero de Incidencias",
            value: "incidents",
          },
        ]
      }
    },
    data() {
      return {
        weeks: [],
        expandedItem: null,
        year: null,
        selectedWeek: null
      };
    },
    watch: {
      expandedItem(val) {
        this.selectedWeek = this.weeks[val];
      }
    },
    methods: {
      getWeeksInMonth() {
        let currentDate = new Date()
        this.year = this.month > currentDate.getMonth() ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
        let requiredDate = new Date(this.year, this.month, -1);
  
        if (currentDate.getMonth() === this.month) {
          requiredDate = currentDate;
        }
        const firstDay = new Date(requiredDate.getFullYear(), requiredDate.getMonth(), 1).getDay();
        let weeks = []
  
        for (let i = 1; i <= Math.ceil((requiredDate.getDate() + (firstDay - 1)) / 7); i++) {
          weeks.push({
            name: `Semana ${i}`,
            id: i,
          })
        }
        this.weeks = weeks;
      },
      replicateEvent(item) {
        this.$emit("expandedDay", item);
      }
    },
    mounted() {
      this.getWeeksInMonth();
    }
  };
  </script>
  
  <style></style>