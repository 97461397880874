<template>
    <v-container class="mt-2">
        <v-row>
            <v-col>
                <h2 class="font-weight-medium grey--text text--darken-2">
                    Celdas
                </h2>
                <v-divider class="mb-w"></v-divider>

                <v-tabs v-model="tab" grow @change="getTableData">
                    <v-tab v-for="(item, index) in items" :key="index.name" >
                        {{item.name}}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <div v-for="item in tableData" :key="item.name" style="display: block">
                      <cell class="mt-2" :cell-name="item.name">
                        <template v-slot:body>
                          <cell-table
                              :data-table="item.items"
                          >
                          </cell-table>
                        </template>
                      </cell>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div v-for="item in tableData" :key="item.name" style="display: block">
                      <cell class="mt-2" :cell-name="item.name">
                        <template v-slot:body>
                          <cell-table-week
                              :data-table="item.items"
                          ></cell-table-week>
                        </template>
                      </cell>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div v-for="item in tableData" :key="item.name" style="display: block">
                      <cell class="mt-2" :cell-name="item.name">
                        <template v-slot:body>
                          <cell-table-month
                              :data-table="item.items"
                          ></cell-table-month>
                        </template>
                      </cell>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div v-for="item in tableData" :key="item.name" style="display: block">
                      <cell class="mt-2" :cell-name="item.name">
                        <template v-slot:body>
                          <cell-table-year
                              :data-table="item.items"
                          ></cell-table-year>
                        </template>
                      </cell>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Cell from '../components/Cell.vue';
import FrequencyTapsEnum from '@/enums/modules/FrequencyTapsEnum'
import InfoTable from '../components/Tables/InfoTable.vue';
import CellTable from '../components/Tables/CellTable'
import CellTableWeek from '../components/Tables/CellTableWeek'
import CellTableMonth from '../components/Tables/CellTableMonth'
import CellTableYear from '../components/Tables/CellTableYear'
import axios from "axios";

export default {
  components: {Cell, InfoTable, CellTable, CellTableWeek, CellTableMonth, CellTableYear},
  data() {
    return {
      tab: 0,
      items: [
        {
          name: "Diario",
          value: FrequencyTapsEnum.DAILY,
        },
        {
          name: "Semanal",
          value: FrequencyTapsEnum.WEEKLY,
        },
        {
          name: "Mensual",
          value: FrequencyTapsEnum.MONTHLY,
        },
        {
          name: "Anual",
          value: FrequencyTapsEnum.YEARLY,
        },
      ],
      tableData: []
    }
  },
  methods: {
    getTableData() {
      axios.get(process.env.VUE_APP_APIURL + `${this.items[this.tab].value}/` + "cellEfficiency/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.tableData = response.data;
          });
    }
  },
  mounted() {
    this.getTableData();
  }
};
</script>

<style></style>
