export const WeekDaysEnum = Object.freeze({
    SUNDAY : "Domingo",
    MONDAY : "Lunes",
    TUESDAY : "Martes",
    WEDNESDAY : "Miercoles",
    THURSDAY : "Jueves",
    FRIDAY : "Viernes",
    SATURDAY : "Sabado",
  });

  export default WeekDaysEnum;