<template>
    <v-data-table
        :headers="headers"
        :items="dataTable"
        hide-default-footer
        :items-per-page="-1"
    >
    </v-data-table>
</template>

<script>
export default {
    props: {
        dataTable: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            headers: [
                { text: "Empleado", value: "employee__name" },
                { text: "Número de pausas", value: "pauses" },
                { text: "Tiempo total de pausa (Hr)", value: "hours" },
            ],
        };
    },
};
</script>
